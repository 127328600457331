<template>
  <div>
    <div class="bg-primary text-center mt-5">
      <span class="text-white font-semibold text-4xl">Proceso Finalizado</span>
    </div>
    <div class="w-full mt-5 text-center">
      <span class="font-semibold mb-10">
        El documento se enviará al correo registrado en el sistema. También
        puede descargarse en PDF en la siguiente opción:
      </span>
      <div class="w-full mt-10">
        <p>
          <svg
            class="w-full h-24"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M459.265 466.286c0 25.248-20.508 45.714-45.806 45.714H98.541c-25.298 0-45.806-20.467-45.806-45.714V45.714C52.735 20.467 73.243 0 98.541 0h196.047a34.386 34.386 0 0124.318 10.068l130.323 130.34a34.248 34.248 0 0110.036 24.217z"
              fill="#f9f8f9"
            />
            <path
              d="M129.442 512H98.537c-25.291 0-45.802-20.47-45.802-45.719V45.719C52.735 20.47 73.246 0 98.537 0h30.905C104.151 0 83.64 20.47 83.64 45.719V466.28c0 25.25 20.511 45.72 45.802 45.72zM459.265 164.623v16.73h-119.46c-34.12 0-61.873-27.763-61.873-61.883V0h16.658a34.4 34.4 0 0124.312 10.065L449.23 140.404a34.252 34.252 0 0110.035 24.219z"
              fill="#e3e0e4"
            />
            <path
              d="M456.185 150.448h-116.38c-17.101 0-30.967-13.866-30.967-30.978V3.101a34.034 34.034 0 0110.065 6.964l130.328 130.339a34.024 34.024 0 016.954 10.044zM440.402 444.008H71.598c-22.758 0-41.207-18.45-41.207-41.207V252.394c0-22.758 18.45-41.207 41.207-41.207h368.805c22.758 0 41.207 18.45 41.207 41.207V402.8c0 22.759-18.45 41.208-41.208 41.208z"
              fill="#dc4955"
            />
            <path
              d="M97.352 444.008H71.598c-22.757 0-41.207-18.451-41.207-41.207V252.394c0-22.757 18.451-41.207 41.207-41.207h25.755c-22.757 0-41.207 18.451-41.207 41.207V402.8c-.001 22.757 18.449 41.208 41.206 41.208z"
              fill="#c42430"
            />
            <g fill="#f9f8f9">
              <path
                d="M388.072 277.037c4.267 0 7.726-3.458 7.726-7.726s-3.459-7.726-7.726-7.726h-47.247a7.725 7.725 0 00-7.726 7.726v116.573c0 4.268 3.459 7.726 7.726 7.726s7.726-3.458 7.726-7.726V334.22h35.768c4.267 0 7.726-3.458 7.726-7.726s-3.459-7.726-7.726-7.726h-35.768v-41.731zM258.747 262.891h-32.276a7.724 7.724 0 00-7.726 7.74v112.095c0 .637.085 1.252.231 1.844v.035a7.728 7.728 0 007.726 7.699h.029c.974-.004 23.943-.093 33.096-.251 15.515-.272 29.33-7.303 38.904-19.798 8.875-11.583 13.763-27.443 13.763-44.657 0-38.703-21.599-64.707-53.747-64.707zm.811 113.71c-5.75.1-17.382.173-25.155.213-.043-12.743-.122-37.877-.122-49.343 0-9.584-.044-35.933-.068-49.127h24.535c28.234 0 38.294 25.442 38.294 49.254-.001 28.467-15.415 48.617-37.484 49.003z"
              />
            </g>
            <path
              d="M146.336 261.444h-32.967c-6.746 0-7.102 2.938-7.102 7.099V386.94a7.099 7.099 0 007.099 7.099 7.099 7.099 0 007.099-7.099v-44.368c7.698-.044 19.916-.107 25.868-.107 22.698 0 41.165-18.173 41.165-40.511-.001-22.337-18.464-40.51-41.162-40.51zm0 66.824c-5.913 0-17.952.061-25.679.106-.044-7.914-.107-20.39-.107-26.419 0-5.066-.036-18.095-.061-26.313h25.846c14.618 0 26.967 12.049 26.967 26.313.001 14.264-12.349 26.313-26.966 26.313z"
              fill="#f9f8f9"
            />
          </svg>
        </p>
      </div>
    </div>
    <div class="flex justify-center w-full mt-10 mb-5" v-if="loading">
      <propagate-loader
        class="w-full flex justify-center spinner"
        color="#3C959D"
        :loading="loading"
        :size="50"
      ></propagate-loader>
    </div>
    <div class="text-center w-full mt-10 mb-5" v-if="show_download_pdf">
      <button
        class="bg-normalYellow hover:bg-hoverYellow text-white text-xl py-1 px-20 rounded-full"
        @click="downloadPdf()"
      >
        Descargar PDF
      </button>
    </div>

    <!-- <div class="text-center w-full mt-5 mb-5 ">
        <button class="w-1/4 bg-darkGray hover:bg-lightGray text-xl py-1 px-20 rounded-full">
            Salir
        </button>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "SuccessfulProcess",
  data() {
    return {
      token_auth_valid: localStorage.getItem("token_auth_valid"),
      url_for_download_pdf: localStorage.getItem("pdf_for_download"),
      show_download_pdf: true,
      loading: false,
    };
  },
  methods: {
    async downloadPdf() {
      this.show_download_pdf = false;
      this.loading = true;
      window.open(this.url_for_download_pdf);
      this.loading = false;
      this.show_download_pdf = true;
      return false;
    },
  },
};
</script>

<style>
</style>
