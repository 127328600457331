<template>
  <div class="container text-center p-10">
    <img
      src="https://pngimage.net/wp-content/uploads/2018/06/404-png-5.png"
      alt="404"
      style="display:block; margin:auto;"
    />
    <router-link type="button" class="hover:underline" to="/">
      <svg
        class="h-8 w-8 fill-current text-blue-900"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="274.835 394.86 54.965 55.15"
      >
        <defs />
        <path
          d="M302.4 395.823c14.752.013 26.694 12.004 26.695 26.805.001 14.592-12.041 26.517-26.753 26.495-14.737-.023-26.754-11.98-26.684-26.551.072-14.873 11.958-26.763 26.742-26.749zm.342 32.534h2.447c3.522 0 7.044.033 10.565-.013 2.327-.03 3.413-1.138 3.479-3.442.039-1.364.033-2.731.003-4.096-.057-2.537-.978-3.536-3.514-3.605-2.801-.076-5.605-.022-8.408-.024-1.471-.001-2.942 0-4.571 0 0-1.899.247-3.457-.079-4.885-.254-1.114-1.01-2.527-1.934-2.949-.783-.357-2.422.2-3.171.899-3.518 3.284-6.88 6.737-10.254 10.171-1.618 1.646-1.652 3.053-.056 4.689 3.413 3.497 6.829 6.997 10.403 10.326.73.68 2.361 1.107 3.203.755.868-.363 1.601-1.754 1.812-2.811.3-1.513.075-3.132.075-5.015z"
        />
      </svg>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "NotFound",

  data() {
    return {};
  },
  beforeMount() {}
};
</script>

<style scoped>
</style>