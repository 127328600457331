  <template>
  <div>
    <div class="flex infline-flex  h-200 w-200">
      <img
        class="mt-10 w-32 h-32 md:w-48 md:h-48"
        style="display: block;
            margin-left: auto;
            margin-right: auto;
            "
        alt="Vue logo"
        src="@/assets/logo-FASM.svg"
      />
    </div>
    <div>
      <h1 class="text-xl md:text-3xl text-center m-4 font-semibold">
        Fondo de Ayuda Sindical Mutualista
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
