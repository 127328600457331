<template>
  <div>
    <div v-if="is_url_expiration == false && url_signed == false">
      <!-- PARTNER DATA SECCION INIT -->
      <div class="flex flex-wrap">
        <div class="headers">
          <h2>Datos del Socio</h2>
        </div>
        <div class="partner-col">
          <p class="text-primary">R.F.C.</p>
          <p class="font-semibold">{{ rfc }}</p>
        </div>
        <div class="partner-col">
          <p class="text-primary">Nombre Completo</p>
          <p class="font-semibold">{{ name }}</p>
        </div>
        <div class="partner-col">
          <p class="text-primary">Telefóno</p>
          <p class="font-semibold">{{ phone }}</p>
        </div>
        <div class="partner-col">
          <p class="text-primary">Correo Electrónico</p>
          <p class="font-semibold">{{ email }}</p>
        </div>
      </div>
      <!-- WORKPLACE DATA SECCION INIT -->
      <div class="flex flex-wrap">
        <div class="headers">
          <h2>Datos del Centro de Trabajo</h2>
        </div>
        <div class="work-col" v-for="data in workplace" :key="data.ct_clave">
          <p class="text-primary">{{ data.label }}</p>
          <p class="font-semibold">{{ data.value }}</p>
        </div>
      </div>
      <!-- BENEFITS DATA SECCION INIT -->
      <div class="w-full bg-goodGreen">
        <div class="headers">
          <h2>Beneficios de Salud</h2>
        </div>
      </div>
      <div class="w-full">
        <!-- Big Screens-->
        <div class="hideInSmallScreens">
          <!-- HEADERS-->
          <div class="flex text-primary h-12 text-center mt-5">
            <div class="w-3/12"><p>Beneficios Adicionales</p></div>
            <div class="w-2/12"><p>Beneficio</p></div>
            <div class="w-2/12"><p>Fecha de Adquisición</p></div>
            <div class="w-1/12"><p>Aportación Qnal.</p></div>
            <div class="w-2/12"><p>Invalidez</p></div>
            <div class="w-2/12">
              <p>Fecha estimada de recuperación</p>
            </div>
          </div>
          <div v-for="(data, index) in benefits" :key="data.beneficio">
            <!-- One row -->
            <div class="flex text-center">
              <div class="w-3/12 font-semibold">
                <p>
                  <span class="mr-5 text-primary">{{ index + 1 }}</span
                  >Defunción
                </p>
              </div>
              <div class="w-2/12">
                <p>{{ data.beneficio }}</p>
              </div>
              <div class="w-2/12">
                <p>{{ data.fecha_adquisicion }}</p>
              </div>
              <div class="w-1/12 text-right">
                <p>{{ data.aportacion_quincenal }}</p>
              </div>
              <div class="w-2/12 font-semibold">
                <p>{{ data.acepto }} Aplica</p>
              </div>
              <div class="w-2/12">
                <p>{{ data.fecha_recuperacion }}</p>
              </div>
            </div>
            <!-- Two row -->
            <div class="flex text-center mb-5">
              <div class="w-3/12">
                <p>Recuperación al término del plazo</p>
              </div>
              <div class="w-2/12">
                <p>{{ data.recuperacion }}</p>
              </div>
              <div class="w-2/12">
                <p class="font-semibold">
                  V.A.P.<span> {{ data.vap }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="flex mt-5">
            <div class="w-7/12 text-right text-primary">
              <p>Aportación total</p>
            </div>
            <div class="w-1/12 text-right">
              <p>{{ ammount_total_benefits }}</p>
            </div>
          </div>
          <div class="w-full text-center mb-5 mt-5">
            <p>
              **Durante la vigencia de su póliza, hasta los 60 años de edad
              siempre y cuando se encuentre en servicio activo.
            </p>
          </div>
        </div>
        <!-- Data Mobile Init -->
        <div class="showInSmallScreens text-center">
          <div class="justify-center">
            <div
              class="w-full mb-5"
              v-for="(data, index) in benefits"
              :key="data.beneficio"
            >
              <div class="w-full inline-flex mt-5">
                <div class="w-1/2">
                  <p class="text-primary">Beneficios Adicionales</p>
                  <p class="font-semibold">
                    <span class="text-primary">{{ index + 1 }} </span>Defunción
                  </p>
                </div>
                <div class="w-1/2">
                  <p class="text-primary">Beneficio</p>
                  <p class="font-semibold">
                    {{ data.beneficio }}
                  </p>
                </div>
              </div>

              <div class="w-full inline-flex mt-5">
                <div class="w-1/2">
                  <p class="text-primary text-center">Fecha de Adquisición</p>
                  <p class="font-semibold text-center">
                    {{ data.fecha_adquisicion }}
                  </p>
                </div>
                <div class="w-1/2">
                  <p class="text-primary text-center">Aportación Qnal.</p>
                  <p class="font-semibold text-center">
                    {{ data.aportacion_quincenal }}
                  </p>
                </div>
              </div>

              <div class="w-full inline-flex mt-5">
                <div class="w-1/2">
                  <p class="text-primary text-center">
                    Recuperación al término del plazo
                  </p>
                  <p class="font-semibold text-center">
                    {{ data.recuperacion }}
                  </p>
                </div>
                <div class="w-1/2">
                  <p class="text-primary text-center">
                    Fecha estimada de recuperación
                  </p>
                  <p class="font-semibold text-center">
                    {{ data.fecha_recuperacion }}
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full mt-8">
              <div class="text-center font-semibold">
                <hr class="ml-8 mr-8" />
                <p class="text-primary">Aportación Total</p>
                <p>{{ ammount_total_benefits }}</p>
              </div>
            </div>
            <div class="w-full text-justify p-5">
              <div>
                <p>
                  **Durante la vigencia de su póliza, hasta los 60 años de edad
                  siempre y cuando se encuentre en servicio activo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- BENEFICIARIES DATA SECCION INIT -->
      <div class="headers">
        <h2>Beneficiarios por fallecimiento del Socio</h2>
      </div>
      <div class="hideInSmallScreens">
        <div class="flex flex-wrap">
          <div class="beneficiaries-col">
            <p class="text-primary">Nombres</p>
            <p
              class="font-semibold"
              v-for="data in beneficiaries"
              :key="data.nombres"
            >
              {{ data.nombres }}
            </p>
          </div>
          <div class="beneficiaries-col">
            <p class="text-primary">Fecha de Nacimiento</p>
            <p
              class="font-semibold"
              v-for="data in beneficiaries"
              :key="data.nombres"
            >
              {{ data.fecha_nacimiento }}
            </p>
          </div>
          <div class="beneficiaries-col">
            <p class="text-primary">Parentesco</p>
            <p
              class="font-semibold"
              v-for="data in beneficiaries"
              :key="data.nombres"
            >
              {{ data.parentesco }}
            </p>
          </div>
          <div class="beneficiaries-col">
            <p class="text-primary">%</p>
            <p
              class="font-semibold"
              v-for="data in beneficiaries"
              :key="data.nombres"
            >
              {{ data.porcentaje }}
            </p>
          </div>
        </div>
      </div>

      <!-- Data Mobile Init -->
      <div class="showInSmallScreens text-center">
        <div
          class="ml-2 mr-2 lg:ml-5 xl:ml-20 mt-5 lg:flex-wrap justify-center"
        >
          <div class="w-full" v-for="data in beneficiaries" :key="data.nombres">
            <div class="w-full mt-5 text-left">
              <p class="text-primary">Nombre</p>
              <p class="font-semibold">{{ data.nombres }}</p>
            </div>

            <div class="w-full inline-flex mt-5">
              <div class="w-1/2">
                <p class="text-primary">Fecha de Nacimiento</p>
                <p class="font-semibold">
                  {{ data.fecha_nacimiento }}
                </p>
              </div>

              <div class="w-1/2">
                <p class="text-primary text-center">Parentesco</p>
                <p class="font-semibold text-center">
                  {{ data.parentesco }}
                </p>
              </div>
            </div>

            <div class="w-full mt-5">
              <p class="text-primary text-center">Porcentaje (%)</p>
              <p class="font-semibold text-center">
                {{ data.porcentaje }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Data Mobile End-->
      <!-- Otros Init-->
      <div class="w-full">
        <div class="">
          <hr
            class="md:block lg:block xl:block hidden mt-10 bg-primary h-px mx-24"
          />
          <div class="mt-10 px-10 lg:px-24">
            <p class="text-xl text-justify">
              Manifiesto en pleno uso de mis facultades mentales a los
              beneficiarios anteriormente estipulados, y que en caso de designar
              como beneficiarios a menores de edad, el tutor de mis
              beneficiarios que plasmo en éste documento, deberá ser avalado por
              la ley y autorizado por un juzgado familiar para recibir las
              cantidades estipuladas de los beneficios de defunción del Fondo de
              Ayuda Sindical Mutualista, de lo contrario, dichos beneficios no
              se entregarán hasta que se cumpla con lo estipulado en la ley.
            </p>
          </div>
          <div class="text-center mt-10 mb-10 px-10 items-center">
            <span>He leído y acepto las condiciones del </span
            ><a
              class="text-primary"
              href=""
              v-on:click.prevent="getPrivacynotice()"
              >Aviso de Privacidad</a
            >
          </div>
          <div class="text-center mb-5">
            <button
              v-bind:class="[
                'hover:bg-darkGray text-xl py-1 px-20 m-2 rounded-full',
                acceptConditions ? 'activeClass' : 'bg-lightGray',
              ]"
              v-on:click="
                (notice_of_privacy_type =
                  'plan_costos_v200501_recibi_reglamento_si'),
                  (acceptConditions = true)
              "
            >
              Sí
            </button>
            <button
              v-bind:class="[
                'hover:bg-darkGray text-xl py-1 px-20 m-2 rounded-full',
                acceptConditions === false ? 'activeClass' : 'bg-lightGray',
              ]"
              v-on:click="
                (notice_of_privacy_type =
                  'plan_costos_v200501_recibi_reglamento_no'),
                  (acceptConditions = false)
              "
            >
              No
            </button>
          </div>

          <div class="text-center">
            <p class="text-primary text-2xl font-semibold">Firma Digital</p>
            <p class="px-5">
              Realice su firma como en su identificación oficial
            </p>
          </div>

          <div class="w-full mt-5">
            <div class="flex flex-col lg:flex-row justify-center">
              <div class="w-full flex justify-center text-primary">
                <VueSignaturePad
                  id="signature"
                  width="45%"
                  height="150px"
                  ref="signaturePad"
                  class="border-solid"
                />
              </div>
            </div>

            <div class="text-center mt-5 mb-5">
              <button
                class="mt-5 bg-darkGray hover:bg-lightGray text-xl py-1 px-20 rounded-full"
                @click="undo"
              >
                Deshacer
              </button>
            </div>
          </div>

          <div class="text-center px-5" v-if="acceptConditions == false">
            <span class="text-red-600 text-xl font-semibold"
              >Debes aceptar las condiciones del Aviso de Privacidad para
              continuar</span
            >
          </div>

          <div class="text-center px-5" v-if="showMsgSign == true">
            <span class="text-red-600 text-xl font-semibold"
              >Ingrese la firma</span
            >
          </div>

          <div class="text-center px-5" v-if="document_signed == true">
            <span class="text-red-600 text-xl font-semibold"
              >El documento ya ha sido firmado</span
            >
          </div>

          <div
            class="flex justify-center w-full mt-10 mb-5"
            v-if="loading_finish"
          >
            <propagate-loader
              class="w-full flex justify-center spinner"
              color="#E77C38"
              :loading_finish="loading_finish"
              :size="50"
            ></propagate-loader>
          </div>

          <div class="text-center mt-5 mb-5" v-if="show_finish">
            <button
              @click="goToPDF"
              class="bg-normalYellow hover:bg-hoverYellow text-white text-xl py-1 px-20 rounded-full"
            >
              Finalizar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="is_url_expiration == true || url_signed == true">
      <div class="bg-primary mt-2 lg:mt-5">
        <span
          class="text-white font-semibold ml-5 lg:ml-20 xl:ml-20 text-3xl md:text-4xl lg:text-4xl xl:text-4xl"
          >{{ errors }}</span
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {
      acceptConditions: null,
      options: {
        penColor: "#1F2933",
      },
      apiCabsaCore: "https://www.cabsacore.com/",
      apiCcapi: "https://api.cabsacore.com/",
      //apiCabsaCore: "http://localhost:3000/",
      //apiCabsaCore: "http://200.92.206.58/",
      token_auth_valid: "",
      loading_finish: false,
      show_finish: true,
      is_previous_letter: false,
      is_new_letter: false,
      is_url_expiration: null,
      url_is_expiration: false,
      errors: null,
      url_response_json: null,
      url_response_pdf: null,
      url_path_response_json: null,
      url_path_response_pdf: null,
      name_catalog_document: "plan_costos_firma",
      url_path_response_pdf_signed: null,
      notice_of_privacy_type: "",
      is_not_document_signed: true,
      urlParams: null,
      file: null,
      json_file: null,
      data: null,
      document_type: "plan_costos",
      url_for_download_pdf: "",
      document_signed: false,
      loading: false,
      rfc: "",
      name: "",
      gender: "",
      email: "",
      phone: "",
      email_associate: "",
      benefits: [],
      ammount_total_benefits: "",
      wroteSign: false,
      showMsgSign: false,

      savings_info: "",
      retirement_amount: "",
      death_info: "",
      death_amount: "",
      death_partner_benefit: "",
      death_family_benefit: "",
      death_recovery: "",
      death_vap: "",
      death_disability: "",
      death_benefit: "",
      benefits_vt: [],
      beneficiariesDecease: [],
      ge_info: "",
      contribution_info: "",
      total_contribution: "",
      aditionalBenefit: "Defunción",
      benefit: "Beneficio",
      compensation: {},

      //init
      workplace: [],
      beneficiaries: [],
      url_signed: false,
      member_id: 0,
      user_id: 0
    };
  },
  async beforeMount() {
    this.urlParams = new URLSearchParams(window.location.search);
    this.file = this.urlParams.get("file");
    this.json_file = this.urlParams.get("json_file");
    console.log("file: ", this.file);
    console.log("json_file: ", this.json_file);
    await this.postLogin();
    await this.postUrlExpiration();
    if (this.url_is_expiration == false) {
      await this.validateStatusUrl();
    }
    if (this.url_is_expiration == false && this.url_signed == false) {
      this.getJsonInformation();
      this.getPdfInformation();
    }
    this.loading = false;
  },
  methods: {
    async postLogin(){
      let config = {
        headers: {
            'Content-Type': 'application/json'
        },
      }

      let data = {
        user: {
          email: "aplm@gcabsa.com.mx",
          password: "cabsa2022",
        },
      };

      await this.axios.post(this.apiCcapi + '/fas/login', data, config)
        .then((dataa) => {
          this.token_auth_valid = dataa.data.token_auth
          localStorage.setItem("token_auth_valid", this.token_auth_valid);
      })
    },
    async getJsonInformation() {
      console.log("json: ", this.json_file);
      //this.errors = null;
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let data = {
        token_auth: this.token_auth_valid,
        file_name: this.json_file,
        business_acronym: "FASM",
        process: "generate_plan_costs_json",
      };
      await this.axios
        .post(this.apiCcapi + "/digital_signatures/get_file_cloud", data, config)
        .then((response) => {
          if (response.data.errors.length == 0){
            this.url_response_json = this.apiCcapi + "/digital_signatures/get_tmp_file?path=" + response.data.response.path
            this.getJsonInformationFromUrl();
            this.is_url_expiration = false;
          } else {
            this.errors = response.data.errors
          }
          this.loading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          this.loading = false;
        });
    },
    async getPdfInformation() {
      //this.errors = null;
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let data = {
        token_auth: this.token_auth_valid,
        file_name: this.file,
        business_acronym: "FASM",
        process: "generate_plan_costs_pdf",
      };
      await this.axios
        .post(this.apiCcapi + "/digital_signatures/get_file_cloud", data, config)
        .then((response) => {
          if (response.data.errors.length == 0){
            this.url_response_pdf = response.data.response.url;
            this.url_path_response_pdf = response.data.response.path;
          } else {
            this.errors = response.data.errors
          }
          this.loading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          this.loading = false;
        });
    },
    getJsonInformationFromUrl() {
      this.axios.get(this.url_response_json, "", "").then((response) => {
        console.log("JSIN: ", response);
        //Partner data
        this.rfc = response.data.rfc;
        this.name = response.data.nombre_completo;
        this.email = response.data.correo;
        this.phone = response.data.telefono;
        this.email_associate = response.data.correo_usuario;
        console.log("email_associate: ", this.email_associate);
        //Workplace data
        let work = response.data.centro_trabajo;
        this.workplace = [
          { label: "Datos de la Escuela o CT", value: work.ct_nombre_escuela },
          { label: "Clave del CT", value: work.ct_clave },
          { label: "Domicilio", value: work.ct_domicilio },
          {
            label: "Nombre del Secretaría Gral. o Representante de C.T.",
            value: work.ct_nombre_representante,
          },
          { label: "Delegación o CT", value: work.ct_delegacion },
          { label: "Zona", value: work.ct_zona == "." ? "" : work.ct_zona },
          { label: "Municipio", value: work.ct_municipio },
          { label: "Clave(s) de la(s) Plaza(s)", value: work.ct_claves_plazas },
          {
            label: "Fecha de ingreso al servicio",
            value: work.ct_fecha_ingreso,
          },
          { label: "Años de Servicio", value: work.ct_antiguedad },
        ];
        this.benefits = response.data.sobrevivencia;

        this.ammount_total_benefits = response.data.aportacion_total;
        this.beneficiaries = response.data.beneficiarios;

        this.user_id = response.data.usuario_id;
        this.member_id = response.data.socio_id;

      });
    },
    async postUrlExpiration() {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      this.loading = true;
      await this.axios.post(this.apiCcapi + "/digital_signatures/expiry_time",
          {
            token_auth: this.token_auth_valid,
            url: window.location.toString(),
            business_acronym: "FASM",
          },
          config
        )
        .then((response) => {
          if (response.data.errors.length == 0) {
            this.url_is_expiration = false;
            return true;
          }
        })
        .catch((error) => {
          error.response.data.errors;
          this.errors = "El documento que desea firmar ya ha vencido, favor de contactar a su asesor.";
          this.url_is_expiration = true;
          this.is_url_expiration = true;
          console.log("url expirada", this.errors);
          return false;
        });
    },
    async validateStatusUrl() {
      this.loading = true;
      const params = {
        token_auth: this.token_auth_valid,
        url: window.location.toString(),
        business_acronym: "FASM",
      };
      await this.axios
        .get(this.apiCcapi + "/digital_signatures/get_status_digital_signature", {
          params,
        })
        .then((response) => {
          if (response.data.response.url_status == "FIRMADO") {
            this.url_signed = true;
            this.errors =
              "El documento que desea firmar ya ha sido firmado, favor de contactar a su asesor.";
          } else {
            this.url_signed = false;
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          this.url_signed = true;
          this.errors =
            "Se presentó un error al validar el estatus de la url, favor de contactar a su asesor.";
        });
    },
    async goToPDF() {
      this.show_finish = false;
      this.loading_finish = true;
      this.save();
      if (this.acceptConditions == true) {
        if (this.wroteSign == true) {
          await this.insertDigitalSignatureToFile();
          await this.postUploadFile();
          if (this.is_not_document_signed == true) {
            await this.sendEmail();
            localStorage.setItem("pdf_for_download", this.url_for_download_pdf);
            this.$router.push({ name: "successful-process" });
          }
          this.document_signed = true;
          this.showMsgSign = false;
        } else {
          console.log("firma vacia");
          this.document_signed = false;
          this.showMsgSign = true;
        }
      }
      this.acceptConditions = false;
      this.loading_finish = false;
      this.show_finish = true;
    },
    save() {
      //método para guardar la firma
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      console.log("isEmpty", isEmpty);
      if (isEmpty) {
        this.wroteSign = false;
        return {
          isEmpty: isEmpty,
          data: data,
        };
      } else {
        this.wroteSign = true;
        return {
          isEmpty: isEmpty,
          data: data.split(",")[1],
        };
      }
    },
    async insertDigitalSignatureToFile() {
      //this.errors = null;

      let base64_signature = this.save().data
      this.registerSignature(base64_signature)

      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      console.log("this.url_path_response_pdf:", this.url_path_response_pdf);
      let data = {
        token_auth: this.token_auth_valid,
        path_file_to_sign: this.url_path_response_pdf,
        business_acronym: "FASM",
        name_catalog_document: this.name_catalog_document,
        //name_catalog_document_notice_of_privacy: "reglamento_no_aplica", //this.notice_of_privacy_type,
        digital_signature: base64_signature,
      };
      await this.axios
        .post(this.apiCcapi + "/digital_signatures/insert_digital_signature_to_file",data,config)
        .then((response) => {
          if (response.data.errors.length == 0) {
            this.url_path_response_pdf_signed = response.data.response.path;
          } else {
            this.errors = true;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          this.loading = false;
        });
    },
    async registerSignature(base64_signature) {
      this.errors = null;
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let data = {
        token_auth: this.token_auth_valid,
        signature_file: base64_signature,
        member_id: this.member_id,
        user_id: this.user_id,
        registered: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString()
      };
      await this.axios
        .post(this.apiCcapi + "/fasm/signature_fondos/create",data,config)
        .then(() => {
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          this.loading = false;
        });
    },
    async postUploadFile() {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let data = {
        token_auth: this.token_auth_valid,
        file: this.url_path_response_pdf_signed,
        document_name: this.document_type,
        business_acronym: "FASM",
        process: "generate_plan_costs_pdf_signed",
        extension: "",
        url_for_sign: window.location.toString(),
      };
      await this.axios
        .post(this.apiCcapi + "/digital_signatures/upload_signed_file", data, config)
        .then((response) => {
          if (response.data.errors.length == 0) {
            this.url_for_download_pdf = response.data.response.url;
          } else {
            this.is_not_document_signed = false;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.is_not_document_signed = false;
        });
    },
    async sendEmail() {
      var emailToSendFirst = "";
      var emailToSendSecond = "";
      if (this.email != "" && this.email_associate == "") {
        emailToSendFirst = this.email;
      }
      if (this.email == "" && this.email_associate != "") {
        emailToSendFirst = this.email_associate;
      }
      if (this.email != "" && this.email_associate != "") {
        emailToSendFirst = this.email;
        emailToSendSecond = this.email_associate;
      }
      console.log("emailToSendFirst", emailToSendFirst);
      let emails = [];
      emails.push(emailToSendFirst);
      if (emailToSendSecond != "") {
        emails.push(emailToSendSecond);
      }
      let message = this.encriptarMensaje();
      this.axios
        .post(this.apiCcapi + "/send_mail", {
          business: "fasm",
          mail: {
            business: "fasm",
            process_type: "firma designacion beneficiarios",
            title: "Firma Digital FASM",
            body_mail: message,
            mailing_list: emails,
            attachment_list: [
              {
                file: this.url_for_download_pdf,
                name: "FirmaDigitalFASM.pdf",
              },
            ],
          },
        })
        .then((response) => {
          console.log("SE ENVIO EL EMAIL", response.data);
          // this.sendedEmailConfirmation()
          this.email = "";
        })
        .catch((error) => {
          console.log(error.data);
        });
    },
    encriptarMensaje() {
      const header = { alg: "HS256", typ: "JWT" };
      const encodedHeader = Buffer.from(JSON.stringify(header)).toString(
        "base64"
      );
      var data = {
        html:
          "<h1>Información del interesado</h1><br><b>Nombre:</b> " +
          this.name +
          "<br><b>Correo: </b>" +
          this.email +
          "<br><b>Celular:</b> " +
          this.phone +
          "<br><br><br> " +
          '<img src="https://firma.autorizo.fasm28.com/img/logo-FASM.9adc1f64.svg" ' +
          'alt="Logo" width="137" heght="160" style="display:block; font-family:Arial; color:#000000; ' +
          'font-size:14px;" border="0" />',
      };
      const encodedPayload = Buffer.from(JSON.stringify(data)).toString(
        "base64"
      );
      var jwtSecret = "u3E*aWShrxOv";
      const crypto = require("crypto");
      const signature = crypto
        .createHmac("sha256", jwtSecret)
        .update(encodedHeader + "." + encodedPayload)
        .digest("base64");
      return encodedHeader + "." + encodedPayload + "." + signature;
    },
    getPrivacynotice() {
      window.open(
        "https://cabsacloud.ams3.digitaloceanspaces.com/store/FASM/Aviso%20de%20Privacidad%20FASM.pdf"
      );
    },
    undo() {
      //método para deshacer firma
      this.$refs.signaturePad.undoSignature();
    },
    change() {
      this.options = {
        penColor: "#1F2933",
      };
    },
    resume() {
      this.options = {
        penColor: "#1F2933",
      };
    },
  },
};
</script>
<style scoped>
.activeClass {
  @apply bg-gray-600;
}
.partner-col {
  @apply w-full pl-5 pb-5;
}
.work-col {
  @apply w-full pl-5 pb-5;
}
.benefits-col {
  @apply w-1/2 p-2;
}
.headers {
  @apply w-full bg-primary p-2 text-white font-semibold text-xl;
}
.subheading {
  @apply w-1/2 text-primary p-2;
}
.beneficiaries-col {
  @apply w-full p-2;
}
@screen md {
  .partner-col {
    @apply w-1/4 pt-4 pb-4 pl-8;
  }
  .headers {
    @apply p-3 text-xl;
  }
  .work-col {
    @apply w-1/3 pt-4 pb-4 pl-8;
  }
  .benefits-col {
    @apply w-2/12 text-center;
  }
  .subheading {
    @apply w-1/6 p-4 text-center;
  }
  .beneficiaries-col {
    @apply w-1/5  text-center p-4;
  }
}
hr {
  border-top: 2px solid #9d4536;
}
#signature {
  border: double 2px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #9d4536, #9d4536);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
@media only screen and (max-width: 650px) {
  .hideInSmallScreens {
    display: none;
  }
}
@media only screen and (min-width: 650px) {
  .showInSmallScreens {
    display: none;
  }
}
</style>